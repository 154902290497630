

















































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import FileButton from "@/components/Button/FileButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import UploadButton from "@/components/Button/UploadButton.vue";
import SendMailButton from "@/components/Button/SendMailButton.vue";
import Tinymce from "@/components/Editor/Tinymce.vue";
import { namespace } from "vuex-class";
import FscMultiselectScroll from "@/components/FscMultiselectScroll.vue";

const CostBearerModule = namespace("cost-bearer");
const EmailsModule = namespace("emails");
const EmailConfigModule = namespace("email-config");
const EmailTemplatesModule = namespace("email-templates");
const MessagingModule = namespace("messaging");

@Component({
  components: {
    FscSimpleCard,
    FscMultiselect,
    FileButton,
    AbortButton,
    UploadButton,
    SendMailButton,
    Tinymce,
    FscMultiselectScroll,
  },
})
export default class PaymentDocumentsEmailForm extends Vue {
  public name = "PaymentDocumentsEmailForm";

  @EmailsModule.Action("sendUserEmail")
  public sendUserEmail: any;

  @EmailConfigModule.Action("findEmailConfigsById")
  public findEmailConfigsByUserId: any;

  @EmailConfigModule.Getter("getDataList")
  public emailConfigs: any;

  @EmailTemplatesModule.Action("findAll")
  public findAllEmailTemplates: any;

  @EmailTemplatesModule.Getter("getDataList")
  public allEmailTemplates: any;

  @EmailsModule.Action("sendEmailWithAttachments")
  public sendEmailWithAttachments: any;

  @EmailsModule.Getter("getIsLoading")
  public emailLoading: any;

  @EmailsModule.Getter("getSuccess")
  public emailSuccess: any;

  @EmailsModule.Action("setBlobFile")
  private setBlobFile: any;

  @EmailsModule.Getter("getBlob")
  private getBlob: any;

  @EmailsModule.Getter("getMimeType")
  private getMimeType: any;

  @MessagingModule.Action("filter")
  public filterStudents: any;

  @MessagingModule.Action("setCustomStudentList")
  public setCustomStudentList: any;

  @MessagingModule.Getter("getDataList")
  public allStudents: any;

  @Prop()
  public currentUser!: any;

  @Prop()
  public selectedRow!: any;

  @Prop()
  public isCostBearer!: any;

  @Prop()
  public singleStudentOnly!: true; // sending e.g a document to a specific person

  public emailSender: any | null = null;
  public emailSubject = "";
  public emailTemplate: any = {
    name: null,
  };
  public emailBody = "";
  public emailTo: any = [];

  public selectedFiles: any = [];
  public selectedFilesNames: any = [];

  public customHeight = false;

  public get disableBtnSend() {
    return !this.emailSender || this.emailTo?.length === 0;
  }

  public created() {
    if (this.currentUser && this.currentUser.id) {
      this.findEmailConfigsByUserId(this.currentUser.id);
    }
  }

  public async mounted() {
    await this.findAllEmailTemplates({
      resource: "email-templates/",
    });
    if (this.isCostBearer) {
      this.$nextTick(() => {
        this.emailTo = !Array.isArray(this.selectedRow) ? [this.selectedRow] : this.selectedRow;
      });
      this.setAttachment();
      return;
    }

    if (this.singleStudentOnly) {
      this.setCustomStudentList([this.selectedRow]);
      this.$nextTick(() => {
        this.emailTo = [this.selectedRow];
      });
    } else {
      await this.fetchEmailStudents();
      if (this.allStudents?.length > 0 && this.selectedRow) {
        this.$nextTick(() => {
          this.emailTo = this.allStudents.filter((student: any) => {
            if (student.name.toLowerCase() === this.selectedRow.name.toLowerCase()) {
              return student;
            }
          });
        });
      }
    }

    this.setAttachment();
  }

  public setAttachment() {
    if (this.getBlob) {
      let mimeType = this.getMimeType.split("/");
      const filename = this.isCostBearer === true ? `Dokumente.${mimeType[1]}` : `Zahlungsdokument.${mimeType[1]}`;
      const myFile = new File([this.getBlob], filename, {
        type: this.getMimeType,
      });
      this.selectedFiles.push(myFile);
      this.selectedFilesNames.push(filename);
    }
  }

  public onAbortEmailSend() {
    const options = {
      blob: null,
      mimeType: null,
    };
    this.setBlobFile(options);
    this.emailTo = [];
    this.$emit("abort-email");
  }

  public onSetEmailBody(e: any) {
    this.emailBody = e;
  }

  public onSelectFileUpload(event: any): void {
    this.selectedFilesNames = [];
    let fileArray = Array.from(event.target.files);
    fileArray.map((file: any) => {
      this.selectedFiles.push(file);
    });
    this.selectedFiles.map((file: any) => {
      this.selectedFilesNames.push(file.name);
    });
  }

  protected removeSelectedFile(index: number): void {
    this.selectedFiles.splice(index, 1);
    this.selectedFilesNames.splice(index, 1);
  }

  public onSubmitSendEmail() {
    let formData = new FormData();
    this.selectedFiles.forEach((file: any) => {
      formData.append("files", file);
    });

    const receiversArray = this.emailTo.map((receiver: any) => {
      if (receiver && receiver.email) {
        return receiver.email;
      }
      return receiver;
    });

    const emails = receiversArray.join();

    const email = {
      emailConfigId: this.emailSender.email ? this.emailSender.id : null,
      to: this.emailTo ? emails : "",
      cc: "",
      subject: this.emailSubject ? this.emailSubject : "",
      body: this.emailBody ? this.emailBody : "",
      repliedEmailId: null,
      repliedAllEmailId: null,
      forwardedEmailId: null,
      emailTemplateId: this.emailTemplate.name ? this.emailTemplate.id : null,
    };
    const emailString = JSON.stringify(email).replace(/[{]/g, "%7B").replace(/[}]/g, "%7D");

    formData.append("email", JSON.stringify(email));
    this.sendEmailWithAttachments(formData);
  }

  public setOption(e: any) {
    if (e) {
      this.emailSender = e;
    } else {
      this.emailSender = this.emailConfigs[0];
    }
  }

  @Watch("emailConfigs", { immediate: true, deep: true })
  public emailConfigChange(newEmailConf: any, oldEmailConf: any) {
    if (newEmailConf !== oldEmailConf && newEmailConf.length > 0 && !this.emailSender) {
      this.emailSender = newEmailConf[0];
    }
  }

  @Watch("emailTemplate", { immediate: true, deep: true })
  public onEmailChange(emailTemplate: any) {
    if (emailTemplate) {
      this.emailSubject = emailTemplate.subject;
      this.emailBody = emailTemplate.body;
    }
  }

  @Watch("selectedRow", { immediate: true, deep: true })
  public onSelectedRow(selectedRow: any) {
    if (selectedRow) {
      this.emailTo = selectedRow.emailAddress ? selectedRow.emailAddress : "";
    }
  }

  @Watch("emailTo", { immediate: true, deep: true })
  public onEmailToChange(emails: any) {
    if (emails.length > 0 && emails.length > 15) {
      this.customHeight = true;
    } else {
      this.customHeight = false;
    }
  }

  public async fetchEmailStudents() {
    await this.filterStudents({
      resource: "students/emails",
      filter: {
        calculateSaldo: true,
        showArchived: false
      },
    });
  }

  public beforeDestroy() {
    const options = {
      blob: null,
      mimeType: null,
    };
    this.setBlobFile(options);
  }
}
